@import '_globals.less';


.tx-filter-contacts {
	.mx-flex();
}

.tx-filter-contacts-column {

}

.tx-filter-contacts-column-50 {
	width: 50%;
}

.tx-filter-contacts-column-100 {
	width: 100%;
}




// **************************************************************
// FORM
// **************************************************************
.tx-filter-contacts {
	.text-blue {
		color: @c-primary-1;
	}

	.apply-filter {
		border-bottom: 2px solid #83B0D4;
		display: block;
		margin: 0 0 10px;
		padding: 0 0 10px;
	}
	.ms-options-wrap {
		position: relative;

		> button {
			background-color: #ffffff;
			border-color: #dbdada;

			&:focus,
			&:hover {
				background-color: #ffffff;
			}

			&:before {
				display: none;
			}

			&:after {
				display: inline-block;
				content: "";
				border-top: .3em solid #424242;
				border-right: .3em solid transparent;
				border-bottom: 0;
				border-left: .3em solid transparent;
				position: absolute;
				right: 10px;
				top: 50%;
			}
		}

		&,
		&.ms-active {
			> button {
				&,
				&:focus {
					font-size: 15px;
					font-weight: 500;
					line-height: 21px;
					margin-top: 0;
					padding: 5px 7px;
				}
			}
		}

		> button:focus,
		&.ms-active > button {
			background: @c-secondary-1;
			border-color: @c-secondary-1;
		}

		&.ms-active {
			> .ms-options {
				.ms-custom-deselectall {
					display: block;
				}
			}
		}

		> .ms-options {
			background: @c-secondary-1;
			border: 0;
			margin-top: 15px;
			padding: 25px 25px 0;

			ul {
				&,
				section > .wrapper &:not(.progress):not(.year-navigation) {
					margin: 0;
					padding: 0;
				}

				input[type="checkbox"] {
					left: 0;
					width: auto;
				}
			}

			> ul {
				li {
					&.selected {
						label {
							background-color: transparent;
						}
					}
				}

				> li {
					float: none !important;

					+ li {
						margin-top: 5px;
					}

					&:last-child {
						margin-bottom: 25px;
					}

					&.optgroup {
						.label {
							padding: 0;
						}

						&,
						& + li.optgroup {
							border-top: 2px solid #ccdfed;
							margin-top: 15px;
							padding: 15px 0 0;
						}

						li {
							+ li {
								margin-top: 5px;
							}
						}
					}
				}

				label {
					border: 0;
					color: @c-primary-1;
					margin: 0;
					padding: 0 0 0 25px;

					&.focused,
					&:focus,
					&:hover {
						background-color: transparent;
					}
				}
			}

			> .ms-selectall {
				&.global {
					margin: 0 0 5px;
				}
			}

			.ms-selectall {
				display: block;
				font-size: 15px;
				font-weight: 500;
				line-height: 21px;
				margin: 10px 0;
				text-transform: none;

				&:focus,
				&:hover {
					text-decoration: none;
				}
			}

			.ms-custom-deselectall {
				.transition(400ms);

				color: #000000;
				display: none;
				margin-bottom: 5px;

				&:focus,
				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	.field-wrap-inner-search {
		position: relative;

		&:before {
			.transform(translateY(-50%));

			background: url('img/ico-magnifier.svg');
			content: '';
			display: block;
			height: 22px;
			position: absolute;
			right: 22px;
			top: 50%;
			width: 22px;
		}

		input {
			padding-right: 34px;
		}
	}

	button.button,
	input[type="submit"] {
		padding-bottom: 9px;
		padding-top: 9px;
		width: auto;

		&:before {
			display: none;
		}
	}

	.teaser.hotline {
		background-color: #0080bc;
		float: right;
		width: 60%;

		h2 {
			font-size: 16px;
			font-weight: normal;
			line-height: 22px;
			position: relative;
			padding-left: 30px;
			text-transform: none;

			&:before {
				background: url('img/ico-phone.svg');
				content: '';
				display: block;
				height: 20px;
				left: 0;
				position: absolute;
				top: 0;
				width: 20px;
			}
		}

		table {
			color: #ffffff;
			margin-bottom: 30px;
			margin-left: 30px;

			td {
				padding: 0 10px 0 0;
			}
		}
	}
}



// **************************************************************
// SECTION
// **************************************************************
.tx-filter-contacts-sections {
	margin-top: 80px;
}

.tx-filter-contacts-section {
	margin: 20px 0 0;

	+ .tx-filter-contacts-section {
		margin-top: 20px;
	}
}

.tx-filter-contacts-section-headline {
	border-top: 2px solid #ccdfed;
	color: #646583;
	font-size: 18px;
	font-weight: bold;
	line-height: 26px;
	margin: 0 0 7px;
	padding: 20px 0 0;
}


// **************************************************************
// GROUP
// **************************************************************
.tx-filter-contacts-group {
	+ .tx-filter-contacts-group {
		margin-top: 20px;
	}
}

.tx-filter-contacts-group-headline {
	color: #6c757d;
	font-size: 17px;
	line-height: 23px;
	margin: 0 0 14px;
}


// **************************************************************
// CONTACT
// **************************************************************
.tx-filter-contacts-group-contacts {
	position: relative;

	.glider-slide {
		min-width: 200px;
	}

	.glider-arrow {
		background: url('img/ico-glider-arrow.svg');
		border: 0;
		height: 25px;
		margin: -17px 0 0;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 15px;

		&:before {
			display: none;
		}

		&.disabled {
			display: none;
		}
	}

	.glider-arrow-prev {
		.transform(translate(-200%, -50%) rotate(-180deg));

		left: 5px;
	}

	.glider-arrow-next {
		.transform(translate(200%, -50%));

		right: 5px;
	}
}

.tx-filter-contacts-contact {
	font-size: 14px;
	padding-right: 10px;
}

.tx-filter-contacts-group-contacts .tx-filter-contacts-contact {
	max-width: 232px;
	margin-bottom: 10px;
}

.tx-filter-contacts-ngroups {
	margin-bottom: 20px;
}

.tx-filter-contacts-contact-name {
	font-weight: bold;
}

.tx-filter-contacts-contact-button,
.button-address-popup {
	background: transparent;
	color: @c-primary-1 !important;
	font-size: inherit;
	font-weight: normal;
	margin: 0;
	min-width:165px;
	padding: 0;
	text-align: left;
	line-height: 24px;

	&:focus,
	&:hover {
		background-color: transparent;
		text-decoration: underline;
	}

	&:after {
		content: '>';
		display: inline-block;
		margin: 0 0 0 5px;
	}

	&:before {
		display: none;
	}
}

.tx-filter-contacts-contact-mail {
	background: url('img/ico-mail.svg');
	cursor: pointer;
	display: block;
	height: 14px;
	margin: 5px 0 0;
	overflow: hidden;
	text-indent: -9999px;
	width: 18px;
}


// **************************************************************
// POPUP
// **************************************************************
.tx-filter-contacts-popup {
	.transition(300ms);

	bottom: 0;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
	z-index: 9999;

	&.js-show {
		opacity: 1;
		visibility: visible;
	}
}

.tx-filter-contacts-popup-shadow {
	background: fadeOut(@c-primary-1, 10%);
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.tx-filter-contacts-popup-inner {
	.border-radius(14px);
	.box-sizing(border-box);
	.transform(translate(-50%, -50%));
	.transition(300ms);

	background: #ffffff;
	left: 50%;
	margin: -100px 0 0;
	max-height: 80%;
	max-width: 500px;
	opacity: 0;
	overflow: auto;
	padding: 21px 21px 42px;
	position: absolute;
	top: 50%;
	visibility: hidden;
	width: 100%;

	.js-show & {
		margin-top: 0;
		opacity: 1;
		visibility: visible;
	}
}

.tx-filter-contacts-popup-close {
	.border-radius(50%);

	background: @c-primary-1;
	height: 25px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 21px;
	text-indent: -9999px;
	top: 21px;
	width: 25px;

	&:after,
	&:before {
		.border-radius(2px);

		background: #ffffff;
		border: 0;
		content: '';
		display: block;
		height: 3px;
		left: 50%;
		margin: -2px 0 0 -7px;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 50%;
	}

	&:after {
		.transform(rotate(-45deg));
	}

	&:before {
		.transform(rotate(45deg));
	}
}

.tx-filter-contacts-popup-label {
	color: @c-primary-1;
	font-weight: bold;
	margin: 0 0 14px;
}

.tx-filter-contacts-popup-title {
	color: #646583;
	font-size: 28px;
	line-height: 26px;
	margin: 0 0 10px;
	text-transform: uppercase;
}

.tx-filter-contacts-popup-position,
.tx-filter-contacts-popup-topics,
.tx-filter-contacts-popup-actions-phone {
	font-size: 14px;
	line-height: 23px;
}

.tx-filter-contacts-popup-position {
	margin: 0 0 15px;
}

.tx-filter-contacts-popup-topics {
	color: #646583;

	strong {
		font-weight: bold;
	}
}

.tx-filter-contacts-popup-actions {
	font-weight: bold;
	padding: 10px 0 0;
	text-align: center;

	.button {
		padding-right: 43px;
		position: relative;

		&:before {
			display: none;
		}

		&:after {
			.transform(translateY(-50%));

			background: url('img/ico-mail-white.svg');
			content: '';
			display: block;
			height: 14px;
			position: absolute;
			right: 15px;
			top: 50%;
			width: 18px;
		}
	}
}

.tx-filter-contacts-popup-actions-phone {
	a {
		color: #646583;
		font-size: 15px;
	}

	&:before {
		background: url('img/ico-phone-grey.svg');
		content: '';
		display: inline-block;
		height: 20px;
		margin: 0 5px 0 0;
		vertical-align: middle;
		width: 20px;
	}
}

.tx-filter-contacts-popup-actions-divider {
	color: #646583;
	display: inline-block;
	margin: 7px 0 14px;
	position: relative;
	vertical-align: top;

	&:after,
	&:before {
		background: #646583;
		content: '';
		display: block;
		height: 1px;
		position: absolute;
		top: 50%;
		width: 60px;
	}

	&:after {
		.transform(translate(-100%));

		right: -15px;
	}

	&:before {
		.transform(translate(100%));

		left: -15px;
	}
}

.js-filter-contacts-overlay-combinations {
	p {
		+ p {
			margin-top: 20px;
		}
	}
}

.js-filter-contacts-overlay-actions {
	.mx-flex(column);
	align-items: flex-start;
	gap: 5px;
	margin: 50px 0 0;
}

.js-filter-contacts-overlay-actions-email {
	margin-top: 10px;
}

.js-filter-contacts-overlay-actions-page {
	display: inline-block;
	font-size: 20px;
	padding: 0 0 0 33px;
	position: relative;

	&:before {
		.transform(translateY(-50%));
		.transition(300ms);

		background: url('img/link.png') no-repeat center / contain;
		content: '';
		display: block;
		height: 25px;
		left: 0;
		position: absolute;
		top: 50%;
		width: 25px;
	}

	&:focus,
	&:hover {
		&:before {
			opacity: 0.5;
		}
	}
}

.js-filter-contacts-overlay-actions-phone {
	display: inline-block;
	font-size: 20px;
	padding: 0 0 0 33px;
	position: relative;

	&:before {
		.transform(translateY(-50%));
		.transition(300ms);

		background: url('img/phone.png') no-repeat center / contain;
		content: '';
		display: block;
		height: 25px;
		left: 0;
		position: absolute;
		top: 50%;
		width: 25px;
	}

	&:focus,
	&:hover {
		&:before {
			opacity: 0.5;
		}
	}
}


.js-filter-contacts-overlay-actions-fax {
	display: inline-block;
	font-size: 20px;
	padding: 0 0 0 33px;
	position: relative;

	&:before {
		.transform(translateY(-50%));
		.transition(300ms);

		background: url('img/fax.png') no-repeat center / contain;
		content: '';
		display: block;
		height: 25px;
		left: 0px;
		position: absolute;
		top: 50%;
		width: 25px;
	}

	&:focus,
	&:hover {
		&:before {
			opacity: 0.5;
		}
	}
}

@media screen and (max-width: 1024px) {
	.tx-filter-contacts-group-contacts {
		.glider-arrow-prev {
			.transform(translate(-150%, -50%) rotate(-180deg));

			left: 0;
		}

		.glider-arrow-next {
			.transform(translate(150%, -50%));

			right: 0;
		}
	}
}

@media screen and (max-width: 985px) {
	.tx-filter-contacts {
		.teaser.hotline {
			float: none;
			margin-bottom: 0;
		}
	}

	.tx-filter-contacts-sections {
		margin-top: 0;
	}

	.tx-filter-contacts-column {
		+ .tx-filter-contacts-column {
			margin-top: 20px;
		}

		+ .tx-filter-contacts-column-teaser {
			margin-top: 40px;
		}
	}

	.tx-filter-contacts-column-50 {
		width: 100%;
	}

	.tx-filter-contacts-column-teaser {
		order: 3;
		-moz-order: 3;
		-webkit-order: 3;

	}

}

@media screen and (max-width: 767px) {
	.tx-filter-contacts-popup-inner {
		max-height: 100%;
	}
}

@media screen and (max-width: 479px) {
	.tx-filter-contacts {
		.teaser.hotline {
			width: 100%;
		}
	}
}